import React, {Component} from 'react';

class Social extends Component {
  render() {
    return (
      <ul className="footer__links--social">
        <li>
          <a href={this.props.facebook} target="_blank"
             rel="noopener noreferrer" className="icon icon-facebook">
            <div className="btn-social facebook">
              <span className="sr-only">Facebook</span>
            </div>
          </a>
        </li>
        <li>
          <a href={this.props.twitter} target="_blank" rel="noopener noreferrer"
             className="icon icon-twitter">
            <div className="btn-social twitter">
              <span className="sr-only">Twitter</span>
            </div>
          </a>
        </li>
        <li>
          <a href={this.props.instagram} target="_blank" rel="noopener noreferrer"
             className="icon icon-instagram">
            <div className="btn-social instagram">
              <span className="sr-only">Instagram</span>
            </div>
          </a>
        </li>
      </ul>
    );
  }
}

export default Social;