import React, {Component} from 'react'
import {Link} from 'gatsby'
import {Col, Row} from 'reactstrap'

// import YoutubeModal from 'components/YoutubeModal/YoutubeModal'

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navToggled: false,
            collapsed: false,
            isOpen: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener('resize', this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
        window.removeEventListener('resize', this.handleScroll, true);
    }

    handleScroll = () => {
        // let scrollPosition = document.documentElement.scrollTop
        let scrollPosition = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        let collapsed = scrollPosition < 20 ? false : true;
        this.setState({
            collapsed
        });
    };

    onMobileClick = (e) => {
        this.setState({
            navToggled: !this.state.navToggled
        });
    }

    toggleModal = (e) => {
        e.preventDefault();

        this.setState({
            modalOpen: !this.state.modalOpen
        });
    };

    toggleNav = () => {
        this.setState({
            navToggled: !this.state.navToggled
        });
    };

    render() {
        const {navToggled, collapsed} = this.state;
        const blueWithoutScroll = this.props.blueWithoutScroll;
        const {navGlobal} = this.props

        return (
            <>
                {navGlobal &&
                <>
                    <header className={`header ${collapsed || blueWithoutScroll ? 'collapsed' : ''}`}>
                        <div className="header__container">
                            <div className="d-none d-lg-block">
                                <nav id="navbarSupportedContent" className="main-nav">
                                    <Row className="h-100 d-flex align-items-center ">

                                        <Col md={2} className="px-0">
                                            <ul className="main-nav--menu header__links--social">
                                                <li>
                                                    <a href={this.props.facebook} target="_blank"
                                                       rel="noopener noreferrer" className="icon icon-facebook">
                                                        <div className="btn-social facebook">
                                                            <span className="sr-only">Facebook</span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={this.props.twitter} target="_blank"
                                                       rel="noopener noreferrer"
                                                       className="icon icon-twitter">
                                                        <div className="btn-social twitter">
                                                            <span className="sr-only">Twitter</span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={this.props.instagram} target="_blank"
                                                       rel="noopener noreferrer"
                                                       className="icon icon-instagram">
                                                        <div className="btn-social instagram">
                                                            <span className="sr-only">Instagram</span>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>

                                        </Col>
                                        <Col md={10}>
                                            <ul className="main-nav--menu h-100 d-flex align-items-center justify-content-end">
                                                <li>
                                                    <Link activeClassName="active" to="/" className="link">Home</Link>
                                                </li>
                                                {/*<li>*/}
                                                {/*    <Link activeClassName="active" to="/uk-europe-tour/" className="link">UK &amp; Europe TOUR</Link>*/}
                                                {/*</li>*/}
                                                <li>
                                                    <Link activeClassName="active" to="/london/" className="link">London</Link>
                                                </li>
                                                <li>

                                                    <Link activeClassName="active" to="/media/"
                                                          className="link">Media</Link>
                                                </li>
                                                <li>
                                                    <Link activeClassName="active" to="/cast-creative/" className="link">Cast
                                                        &amp; Creative</Link>
                                                </li>
                                                {/* <li>
                                                    <Link activeClassName="active" to="/las-vegas/" className="link">Las Vegas</Link>
                                                </li> */}
                                                {/* <li>
                                                    <a href="https://www.tegdainty.com/tour/bat-out-of-hell/" className="link"
                                                       target="_blank" rel="noopener noreferrer">AUS & NZ</a>
                                                </li> */}
                                                <li>
                                                    <a href="https://www.batoutofhellmusicalshop.com/"
                                                       className="link" target="_blank" rel="noopener noreferrer">Store
                                                        UK</a>
                                                </li>
                                                <li>
                                                    <a href="https://batoutofhellmusical.shop.musictoday.com/store/"
                                                       className="link" target="_blank" rel="noopener noreferrer">Store
                                                        US</a>
                                                </li>
                                                <li>
                                                    <a href="https://rockpapermerch.com/collections/bat-out-of-hell-the-musical/"
                                                       className="link" target="_blank" rel="noopener noreferrer">Store
                                                        Canada</a>
                                                </li>
                                                <li>
                                                    <Link activeClassName="active" to="/in-memoriam/" className="link">In Memoriam</Link>
                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>

                                </nav>

                            </div>
                            <button
                                className={`nav-toggle ${navToggled ? 'active' : ''} ${collapsed || blueWithoutScroll ? 'collapsed' : ''} d-lg-none`}
                                onClick={this.toggleNav}
                                aria-expanded="false"
                                aria-controls="navbarSupportedContent"
                                type="button"
                                aria-label="Toggle navigation"

                            >
                                <span className="nav-toggle__burger"/>
                                <span className="nav-toggle__open text--light">Menu</span>
                                <span className="nav-toggle__close text--light">Close</span>
                            </button>
                            {/* <div className="d-md-none text-right">
              <a href="https://www.showclix.com/events/29252" target="_blank" rel="noreferrer noopener" className="btn btn--red">Book Tickets</a>
            </div> */}

                        </div>
                    </header>
                </>
                }
                <nav id="navbarSupportedContent"
                     className={`nav mobile-nav ${navToggled ? 'active' : ''}  ${collapsed || blueWithoutScroll ? 'collapsed' : ''}`}>
                    <ul className="nav__btns">
                        <li>
                            <Link activeClassName="active" to="/" className="link">Home</Link>
                        </li>
                        <li>
                            <Link activeClassName="active" to="/uk-europe-tour/" className="link">UK &amp; Europe TOUR</Link>
                        </li>
                        <li>
                                                    <Link activeClassName="active" to="/london/" className="link">London</Link>
                                                </li>
                        <li>
                            <Link activeClassName="active" to="/media/" className="link">Media</Link>
                        </li>
                        <li>
                            <Link activeClassName="active" to="/cast-creative/" className="link">Cast &amp; Creative</Link>
                        </li>
                        {/* <li>
                            <Link activeClassName="active" to="/las-vegas/" className="link">Las Vegas</Link>
                        </li> */}
                        {/* <li>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.tegdainty.com/tour/bat-out-of-hell/"
                               className="link">Australia</a>
                        </li> */}
                        <li>
                            <a href="https://www.batoutofhellmusicalshop.com" className="link" target="_blank" rel="noopener noreferrer">Store UK</a>
                        </li>
                        <li>
                            <a href="https://batoutofhellmusical.shop.musictoday.com/store/"
                               className="link" target="_blank" rel="noopener noreferrer">Store
                                US</a>
                        </li>
                        <li>
                            <a href="https://rockpapermerch.com/collections/bat-out-of-hell-the-musical"
                               className="link" target="_blank" rel="noopener noreferrer">Store
                                Canada</a>
                        </li>
                        <li>
                            <Link activeClassName="active" to="/in-memoriam/" className="link">In Memoriam</Link>
                        </li>
                    </ul>
                </nav>
                {/* <YoutubeModal isOpen={modalOpen} toggleModal={this.toggleModal} videoId={videoId} /> */}
            </>
        );
    }
}

export default Header;