// import { media } from "utils/Media"
// match bootstrap sizes, also add xxl
const sizes = {
    xs: "0px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px"
};

const fontSizeBase = 1;



const font = {
    family: {
        special: "AlternateGothicW01-No2, Helvetica, Arial, sans-serif",
        light: "proxima-nova-condensed, sans-serif",
        base: "proxima-nova-condensed, sans-serif",
        bold: "proxima-nova-condensed, sans-serif",
    },
    size: {
        base: `${fontSizeBase * .875}rem`, // 14px
        sm: `${fontSizeBase * .75}rem`, // 12px
        md: `${fontSizeBase}rem`, // 16px
        lg: `${fontSizeBase * 1.25}rem`, // 20px
        xl: `${fontSizeBase * 1.5}rem` // 24px
    },
    lineHeight: {
        sm: 1.1,
        base: 1.4,
        headings: 1.2
    },
    h1: {
        size: `${fontSizeBase * 3}rem`,
        lineHeight: `100%`,
        md: {
            size: `${fontSizeBase * 2}rem`,
            lineHeight: `100%`,
        },
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `100%`,
        }
    },
    h2: {
        size: `${fontSizeBase * 1.5}rem`,
        lineHeight: `100%`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        },
        xs: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        },
    },
    h3: {
        size: `${fontSizeBase * 1.5}rem`,
        lineHeight: `100%`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        },
        xs: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.5}rem`,
        },
    },
    h4: {
        size: `${fontSizeBase * 1.5}rem`,
        lineHeight: `${fontSizeBase * 1.8125}rem`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.25}rem`,
        }
    },
    h5: {
        size: `${fontSizeBase * 1.25}rem`,
        lineHeight: `${fontSizeBase * 1.5}rem`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.375}rem`,
        }
    },
    h6: {
        size: `${fontSizeBase * 1.25}rem`,
        lineHeight: `${fontSizeBase * 1.5}rem`,
        sm: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `${fontSizeBase * 1.375}rem`,
        }
    },
    p: {
        size: `${fontSizeBase}rem`,
        lineHeight: `150%`,
        sm: {
            size: `${fontSizeBase}rem`,
            lineHeight: `${fontSizeBase * 1.25}rem`,
        },
        bigger: {
            size: `${fontSizeBase * 1.125}rem`,
            lineHeight: `130%`,
        }
    }
}

export default {
    sizes,
    font,
    transitionBase: "all .4s ease-in-out",
    colors: {
        white: "#ffffff",
        black: "#000000",
        darkblue: "#1B1840",
        lightblue: "#4a90ea",
        yellow: "#FFDE06",
        red: "#FF0000"
    }
};