import React from 'react'
import {Link} from 'gatsby'
import {Container, Row, Col} from 'reactstrap'
import SignUpForm from "../SignUpForm";
import Social from "../Social";
const date = new Date();

const Footer = (props) => (
    <footer className="footer py-2 p-sm-4 text-center">
        <Container>
            <div className="narrow mb-4">
                {props.path === "/uk-europe-tour/" &&
                    <SignUpForm/>
                }
            </div>
            <Row>
                <Col lg={12} className="p-0">
                    <Row>

                        <Col xs={12}>
                            <ul className="footer__links">
                                <li><a href="/privacy-policy" target="_blank">Privacy Policy</a></li>
                                <li className="d-none d-md-inline-block">|</li>
                                <li><a href="/cookie-policy" target="_blank">Cookie Policy</a></li>
                            </ul>
                        </Col>


                        <Col xs={12} className="p-0 text-lg-center">
                            <Social
                              facebook={props.facebook}
                              twitter={props.twitter}
                              instagram={props.instagram}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col xs={12}>
                    <ul className="footer__links">
                        <li>© {date.getFullYear()} Bat Out Of Hell!</li>
                        <li className="d-none d-md-inline-block">|</li>
                        <li>All rights reserved</li>
                        <li className="d-none d-md-inline-block">|</li>
                        <li> Designed and built by <a href="https://www.dewynters.com/"
                                                      target="_blank"
                                                      className="white-text"
                                                      rel="noopener noreferrer">Dewynters</a></li>
                    </ul>
                </Col>


            </Row>
        </Container>
    </footer>
);

export default Footer