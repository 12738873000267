import React, {Component} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import {FormGroup, Input} from 'reactstrap'

const url = "https://batoutofhellmusical.us1.list-manage.com/subscribe/post?u=2f36c1eba84f0edbfa535ad91&amp;id=eba3f50307";


class SignUpForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            emailAddress: '',
        };
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        // Update value in state
        this.setState({
            [name]: value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();

        return {
            MERGE0: this.state.emailAddress,
            MERGE1: this.state.firstName,
            MERGE2: this.state.lastName,
        };
    };

    formatMessage = (message) => {
        if (message.includes("0 - Please enter a value")) {
            return "Please enter your email address."
        }

        if (message.includes("1 - Please enter a value")) {
            return "Please enter your first name."
        }

        if (message.includes("2 - Please enter a value")) {
            return "Please enter your last name."
        }

        if (message.includes("An email address must contain")) {
            return "Please enter a valid email address."
        }

        if (message.includes("The domain portion of the email address is invalid")) {
            return "Please enter a valid email address."
        }

        if (message.includes("This email cannot be added to this list")) {
            return "This email address is already registered. Please enter a different email address."
        }

        return message
    };

    render() {
        const SubmitButton = ({onValidated, submitBtnCopy}) => {
            const submit = () => {
                onValidated({
                    MERGE0: this.state.emailAddress,
                    MERGE1: this.state.firstName,
                    MERGE2: this.state.lastName,
                });
            };
            return (
                <Input
                    className={`btn btn--yellow btn__submit`}
                    type="submit"
                    value={submitBtnCopy}
                    onClick={submit}
                />
            )
        };
        
        let copyColor = "#212529"
        if (this.props.$homepage) {
            copyColor = "#ffffff"
        }

        const submitBtnCopy = this.props.submitBtnCopy ? this.props.submitBtnCopy : 'Sign Up';

        const color = "white";

        return (
            <MailchimpSubscribe
                url={this.props.url ? this.props.url : url}
                render={({subscribe, status, message}) => (
                    <>
                        {this.props.$homepage ?
                          <p className="font-weight-bold text-center" style={{color: copyColor}}>The Bat out of Hell UK and International Tour has now closed. To keep updated on future productions please join the mailing list.</p>
                          :
                          <h2 style={{color: copyColor}}>Sign up to our newsletter</h2>
                        }
                        

                        <FormGroup className="two-field">
                            <div className="col-50">
                                <Input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    placeholder="First Name*"
                                    className={`${this.props.className}__input firstName-field`}
                                    value={this.state.firstName}
                                    onChange={e => {
                                        this.handleChange(e)
                                    }}
                                    style={{
                                        height: "53px"
                                    }}
                                />
                            </div>
                            <div className="col-50">
                                <Input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    placeholder="Last Name*"
                                    className={`${this.props.className}__input lastName-field`}
                                    value={this.state.lastName}
                                    onChange={e => {
                                        this.handleChange(e)
                                    }}
                                    style={{
                                        height: "53px"
                                    }}
                                />
                            </div>
                        </FormGroup>
                      
                      <FormGroup className="one-field">
                            <Input
                                type="email"
                                name="emailAddress"
                                id="emailAddress"
                                placeholder="Email address*"
                                className={`${this.props.className}__input email-field`}
                                value={this.state.emailAddress}
                                onChange={e => {
                                    this.handleChange(e)
                                }}
                                style={{
                                    height: "53px"
                                }}
                            />
                        </FormGroup>

                        <FormGroup className="pt-2 pb-0">
                            <SubmitButton
                                onValidated={formData => subscribe(formData)}
                                submitBtnCopy={submitBtnCopy}
                                className="btn--black"
                            />
                        </FormGroup>
                     
                        {status === "sending" && <div style={{color: color}}>sending...</div>}
                        {status === "error" && (
                            <div
                                style={{
                                    color: "red",
                                    textAlign: "center",
                                    fontWeight: "700"
                                }}
                                dangerouslySetInnerHTML={{__html: this.formatMessage(message)}}
                            />
                        )}
                        {status === "success" && (
                                <div
                                    style={{
                                        color: "green",
                                        textAlign: "center",
                                        fontWeight: "700"
                                    }}
                                    dangerouslySetInnerHTML={{__html: 'Thank you for registering your interest in Bat Out Of Hell!. Stay tuned for further updates and we look forward to welcoming you to Bat Out Of Hell!'}}
                                />
                        )
                        }
                    </>
                )}
            />
        );
    }
}

export default SignUpForm;