import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ThemeProvider} from "styled-components"
import GlobalStyles from "utils/GlobalStyles";
import theme from "utils/Theme"
import {Link} from 'gatsby'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import SEO from './seo'
import 'stylesheets/main.scss';

class Layout extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: true
        };
    }

    render() {
        const {children, headData, displayLogo, navGlobal, path} = this.props;

        let facebook ,instagram ,twitter
   
        if (this.props.uk) {
            facebook = "https://www.facebook.com/BatTheMusical/"
            instagram = "https://www.instagram.com/batthemusical/?hl=en"
            twitter = "https://twitter.com/batthemusical?lang=en"
        }
        if (this.props.us) {
            facebook = "https://www.facebook.com/LVbatoutofhell"
            instagram = "https://www.instagram.com/lvbatoutofhell/"
            twitter = "https://twitter.com/LVbatoutofhell "
        }


        return (
            <ThemeProvider theme={theme}>
                <GlobalStyles/>
                <SEO
                    title={headData.title}
                    description={headData.description}
                    slug={path}
                />

                <noscript className="no-js">Javascript is required to view the full experience of this site.</noscript>

                <div className="outdated-browser" id="outdated-browser"></div>

                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                function get_browser() {
                  var ua = navigator.userAgent || navigator.vendor || window.opera, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\\/))\\/?\\s*(\\d+)/i) || [];
                  if (/trident/i.test(M[1])) {
                    tem = /\\brv[ :]+(\\d+)/g.exec(ua) || [];
                    return { name: 'IE', version: (tem[1] || '') };
                  }
                  if (M[1] === 'Chrome') {
                    tem = ua.match(/\\bOPR\\/(\\d+)/)
                    if (tem != null) { return { name: 'Opera', version: tem[1] }; }
                  }
                  if (window.navigator.userAgent.indexOf("Edge") > -1) {
                    tem = ua.match(/\\Edge\\/(\\d+)/)
                    if (tem != null) { return { name: 'Edge', version: tem[1] }; }
                  }
                  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
                  if ((tem = ua.match(/version\\/(\\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
                  return {
                    name: M[0],
                    version: +M[1]
                  };
                }

                var browser = get_browser()

                function isSupported(browser) {
                  var supported = false;
                  if (browser.name === "Chrome" && browser.version > 38) {
                    supported = true;
                  } else if ((browser.name === "MSIE" || browser.name === "IE") && browser.version > 10) {
                    supported = true;
                  } else if (browser.name === "Firefox" || browser.name === "Edge" || browser.name === "Opera" || browser.name === "Safari") {
                    supported = true;
                  }
                  return supported;
                }

                function isFacebookOrInstagramApp() {
                    var ua = navigator.userAgent || navigator.vendor || window.opera;
                    return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1 || ua.indexOf('Instagram') > -1 || ua.indexOf('Twitter') > -1);
                }

                var isSupported = isSupported(browser);

                if (isFacebookOrInstagramApp()) {
                    isSupported = true;
                }

                if (browser.name === "IE") {
                    document.body.className += 'ie';
                }

                if (!isSupported) {
                setTimeout(function(){
                document.getElementById("outdated-browser").innerHTML = '<p>You are using an outdated browser.<br/> <a href="http://outdatedbrowser.com" class="link" target="_blank" rel="noreferrer noopener">More information</a></p>';
                 }, 1500);
                }
                `,
                    }}
                />
                {/*<Header*/}
                {/*    displayLogo={displayLogo}*/}
                {/*    navGlobal={navGlobal}*/}
                {/*    facebook={facebook}*/}
                {/*    instagram={instagram}*/}
                {/*    twitter={twitter}*/}
                {/*/>*/}

                {children}
                <Footer
                    path={this.props.path}
                    facebook={facebook}
                    instagram={instagram}
                    twitter={twitter}
                />
            </ThemeProvider>
        )
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};


Layout.defaultProps = {
    headData: {
        title: '',
        description: '',
    },
    headerImage: false,
    headerClasses: false,
    display: false,
};

export default Layout
