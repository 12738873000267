import {createGlobalStyle, withTheme} from "styled-components";
import fontFiles from "./Fonts";
import {media} from "utils/Media"

const GlobalStyles = createGlobalStyle`
    /* @font-face {
        font-family: "AlternateGothicW01-No2";
        src:
            url(${fontFiles.AlternateGothicW01WOFF2}) format("woff2"), 
            url(${fontFiles.AlternateGothicW01WOFF}) format("woff");
        font-display: swap;
    }

   @font-face {
        font-family: "Proxima Nova Condensed W01 Lt";
        src:
            url(${fontFiles.ProximaNovaCondensedLightWOFF2}) format("woff2"), 
            url(${fontFiles.ProximaNovaCondensedLightWOFF}) format("woff");
        font-display: swap;
    }
    
    @font-face {
        font-family: "Proxima Nova Condensed W01 Rg";
        src:
            url(${fontFiles.ProximaNovaCondensedRegularWOFF2}) format("woff2"), 
            url(${fontFiles.ProximaNovaCondensedRegularWOFF}) format("woff");
        font-display: swap;
    }
    
    @font-face {
        font-family: "Proxima Nova Condensed W01 SmBd";
        src:
            url(${fontFiles.ProximaNovaCondensedSemiBoldWOFF2}) format("woff2"), 
            url(${fontFiles.ProximaNovaCondensedSemiBoldWOFF}) format("woff");
        font-display: swap;
    }
    
    @font-face {
        font-family: "Proxima Nova Condensed W01 Bol";
        src:
            url(${fontFiles.ProximaNovaCondensedBoldWOFF2}) format("woff2"), 
            url(${fontFiles.ProximaNovaCondensedBoldWOFF}) format("woff");
        font-display: swap;
    }
    
    @font-face {
        font-family: "Proxima Nova Condensed W01Xbold";
        src:
            url(${fontFiles.ProximaNovaCondensedExtraBoldWOFF2}) format("woff2"), 
            url(${fontFiles.ProximaNovaCondensedExtraBoldWOFF}) format("woff");
        font-display: swap;
    }
    
    @font-face {
        font-family: "Proxima Nova Condensed W01 Blk";
        src:
            url(${fontFiles.ProximaNovaCondensedBlackWOFF2}) format("woff2"), 
            url(${fontFiles.ProximaNovaCondensedBlackWOFF}) format("woff");
        font-display: swap;
    } */

    body {
        font-family: "proxima-nova-condensed", sans-serif;
        /* font-family: ${props => props.theme.font.family.body}; */
        font-size: ${props => props.theme.font.size.base};
        font-weight: normal;
        line-height: ${props => props.theme.font.lineHeight.base};
        color: ${props => props.theme.colors.greyDark};
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
    }
    
    h1,
    h2, 
    h3 {
        font-family: ${props => props.theme.font.family.bold};
        font-weight: 700;
        margin-bottom: 1rem;
    }
    
    h4, 
    h5, 
    h6 {
        font-family: ${props => props.theme.font.family.base};
        font-weight: normal;
    }

    h1 {
        font-size: ${props => props.theme.font.h1.sm.size};
        line-height: ${props => props.theme.font.h1.sm.lineHeight};
        
        @media ${media.md} {
            font-size: ${props => props.theme.font.h1.md.size};
            line-height: ${props => props.theme.font.h1.md.lineHeight};
        }
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h1.size};
            line-height: ${props => props.theme.font.h1.lineHeight};
        }
    }
    h2 {
        font-size: ${props => props.theme.font.h2.xs.size};
        line-height: ${props => props.theme.font.h2.xs.lineHeight};
        letter-spacing: 0.03em;
        
        @media ${media.md} {
            font-size: ${props => props.theme.font.h2.size};
            line-height: ${props => props.theme.font.h2.lineHeight};
        }
    }
    h3 {
        font-size: ${props => props.theme.font.h3.xs.size};
        line-height: ${props => props.theme.font.h3.xs.lineHeight};
        
        @media ${media.sm} {
            font-size: ${props => props.theme.font.h3.sm.size};
            line-height: ${props => props.theme.font.h3.sm.lineHeight};
        }
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h3.size};
            line-height: ${props => props.theme.font.h3.lineHeight};
        }
    }
    h4 {
        font-size: ${props => props.theme.font.h4.sm.size};
        line-height: ${props => props.theme.font.h4.sm.lineHeight};
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h4.size};
            line-height: ${props => props.theme.font.h4.lineHeight};
        }
    }
    h5 {
        font-size: ${props => props.theme.font.h5.sm.size};
        line-height: ${props => props.theme.font.h5.sm.lineHeight};
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h5.size};
            line-height: ${props => props.theme.font.h5.lineHeight};
        }
    }
    h6 {
        font-size: ${props => props.theme.font.h6.sm.size};
        line-height: ${props => props.theme.font.h6.sm.lineHeight};
        
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h6.size};
            line-height: ${props => props.theme.font.h6.lineHeight};
        }
    }
   
    img {
        max-width: 100%;
    }
    @media (min-width: 576px) {
        .modal-dialog  {
            max-width: 750px!important;
            margin: 1.75rem auto;
        }
    }
    @media (min-width: 1024px) {
        .modal-dialog  {
            max-width: 850px!important;
            margin: 1.75rem auto;
        }
    }


       

`;

export default withTheme(GlobalStyles);